import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CorporateGovernance from "./Components/CorporateGovernance";
import QuikWalletFAQ from "./Components/QuikWalletFAQ";
import PamentSuccess from "./Components/PamentSuccess";
import OrderFastag from "./Components/OrderFastag";
import PamentFail from "./Components/PamentFail";
import Quikwallet from "./Components/Quikwallet";
import Insurance from "./Components/Insurance";
import "slick-carousel/slick/slick-theme.css";
import Products from "./Components/Products";
import Prepaids from "./Components/Prepaids";
import ViewBlog from "./Components/ViewBlog";
import Roadside from "./Components/Roadside";
import Fasttag from "./Components/Fasttag";
import AboutUs from "./Components/AboutUs";
import "slick-carousel/slick/slick.css";
import Terms from "./Components/Terms";
import Main from "./Components/Main";
import Blog from "./Components/Blog";
import FAQ from "./Components/FAQ";
import Gps from "./Components/Gps";
import Services from "./Components/Services";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Prepaid" element={<Prepaids />} />
          <Route path="/Fasttag" element={<Fasttag />} />
          <Route path="/OrderFastag" element={<OrderFastag />} />
          <Route path="/Quikwallet" element={<Quikwallet />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/viewBlog" element={<ViewBlog />} />
          <Route path="/Gps" element={<Gps />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Roadside" element={<Roadside />} />
          <Route path="/Insurance" element={<Insurance />} />
          <Route path="/success" element={<PamentSuccess />} />
          <Route path="/failure" element={<PamentFail />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/CorporateGovernance"
            element={<CorporateGovernance />}
          />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/QuikWalletFAQ" element={<QuikWalletFAQ />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
