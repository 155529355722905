import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import Load from "../images/load.gif"

function OrderFastag() {
  const [form, setform] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      agentId: form.agentId,
      name: form.name,
      email: form.email,
      mobileNumber: form.mobileNumber,
      vechileNumber: form.vechileNumber,
    };

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/roadsideAssistance/addroadsideassistance",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              agentId: "",
              name: "",
              email: "",
              mobileNumber: "",
              vechileNumber: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };
  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container">
            <div className="section_eight" style={{ marginTop: "180px" }}>
              <div className="content_wrapper">
                <div className="title_text">RoadSide Assistance</div>
                <div className="form_wrapper">
                  <div className="row">
                    <div className="col-lg-12 right_sec">
                      <Form
                        onSubmit={(e) => {
                          formsubmit(e);
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Agent Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Agent Id"
                                name="agentId"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={form.agentId}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}></Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                pattern="[A-Za-z\s]{2,50}"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={form.name}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                name="email"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={form.email}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Phone"
                                name="mobileNumber"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                pattern="[0-9]{10}"
                                minLength="10"
                                maxLength="10"
                                required
                                value={form.mobileNumber}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Vehicle Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Vehicle Number"
                                name="vechileNumber"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                pattern="[A-Za-z0-9]{2,10}"
                                value={form.vechileNumber}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <button
                              style={{ float: "right" }}
                              type="submit"
                              class="cta_btn form_submit_resp "
                            >
                              Register
                            </button>
                          </Col>{" "}
                        </Row>
                      </Form>
                      <div className="respText" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default OrderFastag;
