import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Load from "../images/load.gif";
import { ToastContainer, toast } from "react-toastify";

function Prepaids() {
  const [blog, setblog] = useState([]);

  const api = "https://api.starroutes.com/";

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas3();
  }, []);

  const getid = sessionStorage.getItem("Catid");

  const datas3 = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getcategorybyid",
        { _id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setblog(res.data.data);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      phoneNumber: form.phoneNumber,
      address: form.address,
      description: form.description,
      categoryId: blog._id,
    };

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/categoryForm/addcategoryform",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              phoneNumber: "",
              address: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} height="220px"></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container fastag_section">
            <div className="fastag_section_two mt-5">
              <div className="content_wrapper row">
                <div className="col-lg-6 col-md-12 col-sm-12 left_sec">
                  <div className="title_text">{blog.name}</div>
                  <div className="sub_text">{blog.description}</div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 right_sec">
                  <img src={api + blog.image} alt="" />
                </div>
              </div>
            </div>
            <div className="section_eight">
              <div className="content_wrapper">
                <div className="title_text">Get in touch with us!</div>
                <div className="form_wrapper">
                  <div className="row">
                    <div className="col-lg-12 right_sec">
                      <form
                        class="livquik_contactUs"
                        onSubmit={(e) => {
                          formsubmit(e);
                        }}
                      >
                        <div class="input_row row">
                          <div class="form_group_input col-lg-6">
                            <input
                              type="text"
                              class="form_input"
                              id="name"
                              placeholder="Name"
                              autocomplete="new-password"
                              name="name"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.name}
                            />
                            <p class="errTxt ">Enter name</p>
                          </div>

                          <div class="form_group_input col-lg-6">
                            <input
                              type="text"
                              class="form_input"
                              id="phone"
                              placeholder="Phone number"
                              maxlength="10"
                              minLength="10"
                              pattern="[0-9]{10}"
                              autocomplete="new-password"
                              name="phoneNumber"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.phoneNumber}
                            />
                            <p class="errTxt ">Enter your mobile number</p>
                          </div>
                        </div>
                        <div class="input_row row">
                          <div class="form_group_input textarea_input col-lg-6">
                            <textarea
                              class="form_input"
                              id="Description"
                              rows="3"
                              required
                              placeholder="Address"
                              name="address"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.address}
                            ></textarea>
                          </div>

                          <div class="form_group_input textarea_input col-lg-6">
                            <textarea
                              class="form_input"
                              id="Description"
                              placeholder="Your Description"
                              rows="3"
                              required
                              name="description"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.description}
                            ></textarea>
                          </div>
                        </div>
                        <button type="submit" class="form_submit " disabled="">
                          <img
                            src="../../assets/images/telegram_arrow.svg"
                            alt=""
                          />
                        </button>
                        <button type="submit" class="cta_btn form_submit_resp">
                          Submit
                        </button>
                      </form>
                      <div className="respText" />
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Prepaids;
