import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import Load from "../images/load.gif"

function FAQ() {
  const [security, setsecurity] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [generalfaqs, setgeneralfaqs] = useState([]);

  const [paymentrelatedqueries, setpaymentrelatedqueries] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setsecurity(res.data.faqs?.security);
            setgeneralfaqs(res.data.faqs?.generalfaqs);
            setpaymentrelatedqueries(res.data.faqs?.paymentrelatedqueries);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {" "}
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container faq_container">
            <div className="faq_section">
              <div className="title_text">FAQ</div>
              <div className="accordion_wrapper">
                <div className="faq_type">General FAQ</div>
                {generalfaqs.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}

                <div className="faq_type mt-5">Payment Related Queries</div>

                {paymentrelatedqueries.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}

                <div className="faq_type mt-5">Security</div>
                {security.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}
              </div>
            </div>
          </div>{" "}
          <Footer />
        </>
      )}
    </div>
  );
}

export default FAQ;
