import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Header() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const [lgShow, setLgShow] = useState(false);

  const [show, setshow] = useState(false);

  const [show1, setshow1] = useState(false);

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      companyName: form.companyName,
      yourEnquiry: form.yourEnquiry,
    };

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              yourEnquiry: "",
            });
            setLgShow(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const shows =
    show == true ? "collapse navbar-collapse show" : "collapse navbar-collapse";

  const shows1 = show1 == true ? "dropdown-menu show" : "dropdown-menu";

  const [cat, setcat] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallcategorys",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setcat(res.data.categorys);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("");
          }
        }
      );
  };

  const navigate = useNavigate();

  const databyid = (data) => {
    sessionStorage.setItem("Catid", data._id);
    navigate("/services");
  };

  return (
    <div>
      <div class="livquik_header container-fluid">
        <div class="header_wrapper">
          <nav class="navbar navbar-expand-lg">
            <div class="navbar_wrapper">
              <NavLink to="/">
                <a class="navbar-brand">
                  <img src="assets/images/logo.png" alt="" />
                </a>
              </NavLink>
              <div className={shows} id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <NavLink to="/Fasttag">
                      <a class="nav-link">Fastag</a>{" "}
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/OrderFastag">
                      <a class="nav-link">Order Fastag</a>{" "}
                    </NavLink>
                  </li>

                  <li
                    className="nav-item dropdown"
                    onMouseEnter={() => {
                      setshow1(true);
                    }}
                    onMouseLeave={() => {
                      setshow1(false);
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded={show1 ? "true" : "false"}
                    >
                      Services
                    </a>
                    <div
                      className={`dropdown-menu ${show1 ? "show" : ""}`}
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink to="/Prepaid" className="dropdown-item">
                        Prepaid
                      </NavLink>
                      <NavLink to="/Roadside" className="dropdown-item">
                        Road Side Assistance
                      </NavLink>
                      <NavLink to="/Insurance" className="dropdown-item">
                        Insurance
                      </NavLink>

                      {cat.map((data, i) => {
                        return (
                          <>
                            <a
                              href=""
                              onClick={() => {
                                databyid(data);
                              }}
                              className="dropdown-item"
                              key={i}
                            >
                              {data.name}
                            </a>{" "}
                          </>
                        );
                      })}
                    </div>
                  </li>

                  <li class="nav-item">
                    <NavLink to="/Products">
                      <a class="nav-link"> Gps Services</a>
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/Quikwallet">
                      <a class="nav-link">Quikwallet</a>{" "}
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/AboutUs">
                      <a class="nav-link">About</a>{" "}
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/Blog">
                      <a class="nav-link">Blog</a>{" "}
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <div class="cta_btn" onClick={() => setLgShow(true)}>
                      book a demo
                    </div>
                  </li>
                </ul>
              </div>
              <button
                class="navbar-toggler"
                onClick={() => {
                  setshow(!show);
                }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </nav>
        </div>
      </div>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="section_eight">
            <div className="content_wrapper">
              <div className="title_text">Get in touch with us!</div>
              <div className="form_wrapper">
                <div className="row">
                  <div className="col-lg-12 right_sec">
                    <Form
                      onSubmit={(e) => {
                        formsubmit(e);
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="name"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.name}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.email}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Company Name"
                              name="companyName"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.companyName}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone"
                              required
                              pattern="[0-9]{10}"
                              minLength="10"
                              maxLength="10"
                              name="phoneNumber"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.phoneNumber}
                            />
                          </Form.Group>
                        </Col>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Your Enquiry</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Your Enquiry"
                            rows={3}
                            required
                            name="yourEnquiry"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.yourEnquiry}
                          />
                        </Form.Group>
                      </Row>

                      <button
                        type="submit"
                        class="cta_btn form_submit_resp "
                        disabled=""
                      >
                        Submit
                      </button>
                    </Form>
                    <div className="respText" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default Header;
