import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import Load from "../images/load.gif"

function Prepaids() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="prepaid-container">
            <div className="prepaid-section-one">
              <div className="prepaid-content-one">
                <h2>
                  Prepaid is back &amp; our stack has got you covered every step
                  of the way
                </h2>
                <p>
                  Create world-class products on an API-powered platform, get
                  consumer/corporate BINS, and launch swiftly. Issue, manage,
                  and customize physical and virtual prepaid card programs at
                  scale.&nbsp;
                </p>
                <div className="dark-boxes">
                  <span>open loop cards</span>
                  <span>closed loop cards</span>
                  <span>interoperable</span>
                  <span>multi-tenancy</span>
                </div>
              </div>
            </div>

            <div className="prepaid-section-two">
              <div className="prepaid-content-two">
                <h2>Payment solutions for every business need&nbsp;</h2>
                <div className="trinity-boxes">
                  <div className="trinity-box">
                    <div className="trinity-box-content">
                      <img
                        src="../assets/images/prepaid/credit-card-box.svg"
                        alt=""
                      />
                    </div>
                    <p>
                      Prepaid <br />
                      Card
                    </p>
                  </div>
                  <div className="trinity-box">
                    <div className="trinity-box-content">
                      <img
                        src="../assets/images/prepaid/purse-box.svg"
                        alt=""
                      />
                    </div>
                    <p>
                      Prepaid <br />
                      Wallet
                    </p>
                  </div>
                  <div className="trinity-box custom-trinity-last-box">
                    <div className="trinity-box-content">
                      <img src="../assets/images/prepaid/gift-box.svg" alt="" />
                    </div>
                    <p>
                      Gift <br />
                      Cards
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="prepaid-section-three">
              <div className="prepaid-content-three">
                <div>
                  <h2>Set up cards programs instantly with STAR ROUTES </h2>
                  <span>
                    choose api model or go with our white labeled product
                  </span>
                </div>
                <div className="four-boxes-container">
                  <div className="four-boxes">
                    <div className="main-boxes">
                      <div className="gradient-box">
                        <img
                          src="../assets/images/prepaid/abc-final-one.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Set your product name, type, wallet, ranges &amp; KYC
                        parameters
                      </p>
                    </div>
                    <div className="main-boxes">
                      <div className="gradient-box">
                        <img
                          src="../assets/images/prepaid/setting-final-two.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Set card &amp; transaction limits, activation, card
                        design &amp; renewals
                      </p>
                    </div>
                    <div className="main-boxes">
                      <div className="gradient-box">
                        <img
                          src="../assets/images/prepaid/travel-final-three.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Set fees, MCC codes, currency type &amp; exchange rates
                      </p>
                    </div>
                    <div className="main-boxes control-box">
                      <div className="gradient-box">
                        <img
                          src="../assets/images/prepaid/printer-final-four.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Set print &amp; delivery vendors, and audit logs &amp;
                        reports
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="prepaid-section-four">
              <div className="prepaid-content-four">
                <div>
                  <h2>
                    Issue &amp; manage cards in real time with our Control
                    Centre
                  </h2>
                  <div className="gradient-box">
                    <span>
                      OUR CONTROL CENTRE doubles up as a corporate card portal
                    </span>
                  </div>
                </div>
                <div className="four-boxes-container">
                  <div className="four-boxes">
                    <div className="main-boxes">
                      <div className="white-box">
                        <img
                          src="../assets/images/prepaid/abc-img.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Issue new cards based on type using a multi level access
                        control
                      </p>
                    </div>
                    <div className="main-boxes">
                      <div className="white-box">
                        <img
                          src="../assets/images/prepaid/lock-img.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Manage card inventory &amp; branch funding at wallet
                        level
                      </p>
                    </div>
                    <div className="main-boxes">
                      <div className="white-box">
                        <img
                          src="../assets/images/prepaid/travel-img.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Enter customer &amp; beneficiary details, fee waiver
                        &amp; load amounts
                      </p>
                    </div>
                    <div className="main-boxes control-box">
                      <div className="white-box">
                        <img
                          src="../assets/images/prepaid/printer-img.svg"
                          alt=""
                        />
                      </div>
                      <p>
                        Manage customer requests-block, refunds, set limits,
                        raise disputes, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="prepaid-section-five">
              <div className="prepaid-content-five">
                <h2>One Stack. Fully flexible.</h2>
                <div className="slider-section">
                  <div className="slider-content">
                    <Slider {...settings}>
                      <div
                        data-index={-5}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Transaction History</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={-3}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Consumer &amp; Corporate BINS</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={0}
                        className="slick-slide slick-active slick-current"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Notifications</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={1}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Transaction History</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={2}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Embedded vKYC Stack</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={3}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Consumer &amp; Corporate BINS</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={4}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Wallet to Bank Transfers</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={5}
                        className="slick-slide"
                        tabIndex={-1}
                        aria-hidden="true"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Customized Experience</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={6}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Notifications</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={7}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Transaction History</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={8}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Embedded vKYC Stack</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={9}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Consumer &amp; Corporate BINS</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={10}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Wallet to Bank Transfers</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={11}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Customized Experience</span>
                          </div>
                        </div>
                      </div>
                    </Slider>

                    <Slider {...settings1}>
                      <div
                        data-index={11}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>3DS Authentication</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={10}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>E-collect Loading</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={9}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Live on NFS – ATM Cash</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={8}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Tokenization</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={7}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Webhooks</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={6}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Events</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={5}
                        className="slick-slide slick-active slick-current"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>3DS Authentication</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={4}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>E-collect Loading</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={3}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Live on NFS – ATM Cash</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={2}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Tokenization</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={1}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Webhooks</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={0}
                        className="slick-slide"
                        tabIndex={-1}
                        aria-hidden="true"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Events</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={-1}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>3DS Authentication</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={-2}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>E-collect Loading</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={-3}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Live on NFS – ATM Cash</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={-4}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Tokenization</span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-index={-5}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Webhooks</span>
                          </div>
                        </div>
                      </div>
                    </Slider>

                    {/* <div className="slick-slider slick-initialized" dir="ltr">
                  <div className="slick-list"> */}

                    {/* <div
                      className="slick-track"
                      style={{ width: "340%", left: "-100%" }}
                    > */}
                    <Slider {...settings}>
                      <div
                        data-index={-5}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Beneficiary management</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={-4}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Fraud risk rule creation</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={-3}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>MCC Level Limits</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={-2}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Card renewal methods</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={-1}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Reports</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={0}
                        className="slick-slide slick-active slick-current"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>BIN Level Limits</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={1}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Beneficiary management</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={2}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Fraud risk rule creation</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={3}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>MCC Level Limits</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={4}
                        className="slick-slide slick-active"
                        tabIndex={-1}
                        aria-hidden="false"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Card renewal methods</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={5}
                        className="slick-slide"
                        tabIndex={-1}
                        aria-hidden="true"
                        style={{ outline: "none", width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Reports</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={6}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>BIN Level Limits</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={7}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Beneficiary management</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={8}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Fraud risk rule creation</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={9}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>MCC Level Limits</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={10}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Card renewal methods</span>
                          </div>
                        </div>
                      </div>

                      <div
                        data-index={11}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "5.882352941176471%" }}
                      >
                        <div>
                          <div
                            className="white-slider-box"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <span>Reports</span>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </Slider>
                    {/* </div>
                </div> */}
                  </div>
                </div>
                <div className="physical-cards-container">
                  <div className="physical-cards">
                    <div className="phy-card">
                      <div className="carders">
                        <img
                          src="../assets/images/prepaid/card-one.jpg"
                          alt=""
                        />
                        <p>Enabling influencers monetize their clout</p>
                      </div>
                    </div>

                    <div className="phy-card">
                      <div className="carders">
                        <img
                          src="../assets/images/prepaid/card-two.png"
                          alt=""
                        />
                        <p>Equipping the next generation</p>
                      </div>
                    </div>
                    <div className="phy-card">
                      <div className="carders">
                        <img
                          src="../assets/images/prepaid/card-three.jpg"
                          alt=""
                        />
                        <p>Financial power to 500K senior citizens</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prepaid-section-six">
              <div className="prepaid-content-six">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xs-12 left">
                    <img
                      src="../assets/images/prepaid/manage-card.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 right">
                    <h2>Expense Management Cards</h2>
                    <p>
                      Volopay is a premium prepaid card for employee expenses
                      that gives employee access to company funds with smart
                      spending by providing them with the best in class prepaid
                      card experience.
                    </p>
                    <img src="../assets/images/prepaid/volo-pay.svg" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xs-12 left">
                    <img
                      src="../assets/images/prepaid/contactless-card.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 right">
                    <h2>Contactless Payment Wallet</h2>
                    <p>
                      7Rings is a digital wallet that you can wear on your
                      finger. It is India's first contacless payment ring which
                      is linked to a digital prepaid wallet which you can top-up
                      using our apps.
                    </p>
                    <img
                      src="../assets/images/prepaid/contact_less_logo.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xs-12 left">
                    <img src="../assets/images/prepaid/gift-card.jpg" alt="" />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 right">
                    <h2>Shopping Card</h2>
                    <p>
                      GoSats is India's most rewarding Prepaid Card that lets
                      you earn higher percentages on your favorite brands!
                    </p>
                    <img src="../assets/images/prepaid/ghost-pay.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="prepaid-section-seven">
              <div className="prepaid-content-seven">
                <h2>And a whole range of other types of cards</h2>
                <div className="card-wrapper-container">
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img src="../assets/images/prepaid/GPR-Card.svg" alt="" />
                    </div>
                    <p>GPR CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Payroll-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>payroll CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Transit-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>TRANSIT/ NCMC CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Travel-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>TRAVEL CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Incentive-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>INCENTIVE CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Business-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>business card</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Insurance-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>insurance CARD</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Student-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>student cards</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Elderly-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>elderly card</p>
                  </div>
                  <div className="card-wrapper">
                    <div className="card-img">
                      <img
                        src="../assets/images/prepaid/Health-Card.svg"
                        alt=""
                      />
                    </div>
                    <p>health card</p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <Footer />
        </>
      )}
    </div>
  );
}

export default Prepaids;
