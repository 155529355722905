import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Load from "../images/load.gif";

function Fasttag() {
  const [isLoading, setIsLoading] = useState(false);

  const [Industry, setIndustry] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.starroutes.com/";

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setIndustry(res.data.fleetbusiness);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  
  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container fastag_section">
            <div className="fastag_section_one">
              <div className="content_wrapper">
                <div className="title_text">
                  Better control over your funds &amp; vehicles with FASTag
                </div>
                <div className="sub_text">
                  Transforming mobility in India with top-notch technological
                  solutions catered to fintechs and fleet operators seeking
                  innovative financial products&nbsp;
                </div>
                <div className="cta_btn">
                  <a
                    target="_blank"
                    href="#"
                  >
                  Book A Demo
                  </a>
                </div>
              </div>
            </div>
            <div className="fastag_section_two">
              <div className="content_wrapper row">
                <div className="col-lg-6 col-md-12 col-sm-12 left_sec">
                  <div className="title_text">
                    Unleashing the power of FASTag
                  </div>
                  <div className="sub_text">
                    India’s first multi-purpose FASTag integrated with a digital
                    wallet. Enjoy unparalleled convenience as you leverage the
                    power of FASTag and streamline your transactions like never
                    before.
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 right_sec">
                  <img src="../assets/images/fastag_app_screen.png" alt="" />
                </div>
              </div>
            </div>
            <div className="fastag_section_three">
              <div className="content_wrapper">
                <div className="info_text">
                  India’s 1st Non-banking PPI (Prepaid Payment Instrument) to
                  get FASTag issuing license from NETC
                </div>
                <div className="card_wrapper">
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_card.svg" alt="" />
                    </div>
                    <div className="card_name">
                      FASTag <br />+ Card
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_multi.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Multi-vehicle <br />
                      management
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_funds.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Instantly load &amp; <br />
                      Manage Funds
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_fuel.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Pay for Fuel <br />
                      with FASTag
                    </div>
                  </div>
                </div>
                <div className="card_wrapper">
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_repair.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Pay for Repairs <br />
                      with FASTag
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_toll.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Pay for Tolls <br />
                      with FASTag
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_parking.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Pay for Parking <br />
                      with FASTag
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/fastag_atm.svg" alt="" />
                    </div>
                    <div className="card_name">
                      E-Commerce, POS, <br />
                      ATM enabled
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fastag_section_four">
              <div className="content_wrapper">
                <div className="title_text">
                  Helping Banks, Fintechs, Fleet <br />
                  owners &amp; aggregators
                </div>
                <div className="carousel_wrapper">
                  <div className="slick-slider slick-initialized" dir="ltr">
                    <div className="slick-list">
                      <div
                        className="slick-track"
                        style={{ width: "700%", left: "-100%" }}
                      >
                        <div
                          data-index={-2}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_source.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Single Source <br />
                                for all Trip <br />
                                Payments
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={-1}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_onboard.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Hassle-Free <br />
                                Onboarding
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={0}
                          className="slick-slide slick-active slick-current"
                          tabIndex={-1}
                          aria-hidden="false"
                          style={{
                            outline: "none",
                            width: "8.333333333333334%",
                          }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_fin.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Customize a financial <br />
                                layer over your <br />
                                existing services
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={1}
                          className="slick-slide slick-active"
                          tabIndex={-1}
                          aria-hidden="false"
                          style={{
                            outline: "none",
                            width: "8.333333333333334%",
                          }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_vehicle.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Complete vehicle <br />
                                and expense <br />
                                management&nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={2}
                          className="slick-slide"
                          tabIndex={-1}
                          aria-hidden="true"
                          style={{
                            outline: "none",
                            width: "8.333333333333334%",
                          }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_transparency.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Increased accountability &amp; <br />
                                transparency for Fleet <br />
                                owners and Drivers
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={3}
                          className="slick-slide"
                          tabIndex={-1}
                          aria-hidden="true"
                          style={{
                            outline: "none",
                            width: "8.333333333333334%",
                          }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_source.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Single Source <br />
                                for all Trip <br />
                                Payments
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={4}
                          className="slick-slide"
                          tabIndex={-1}
                          aria-hidden="true"
                          style={{
                            outline: "none",
                            width: "8.333333333333334%",
                          }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_onboard.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Hassle-Free <br />
                                Onboarding
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={5}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_fin.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Customize a financial <br />
                                layer over your <br />
                                existing services
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={6}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_vehicle.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Complete vehicle <br />
                                and expense <br />
                                management&nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={7}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_transparency.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Increased accountability &amp; <br />
                                transparency for Fleet <br />
                                owners and Drivers
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={8}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_source.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Single Source <br />
                                for all Trip <br />
                                Payments
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-index={9}
                          tabIndex={-1}
                          className="slick-slide slick-cloned"
                          aria-hidden="true"
                          style={{ width: "8.333333333333334%" }}
                        >
                          <div>
                            <div
                              className="card_wrapper item"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <img
                                src="../assets/images/fintech_onboard.svg"
                                alt=""
                              />
                              <div className="card_info">
                                Hassle-Free <br />
                                Onboarding
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul style={{ display: "block" }} className="slick-dots">
                      <li className="slick-active">
                        <button>1</button>
                      </li>
                      <li className>
                        <button>2</button>
                      </li>
                      <li className>
                        <button>3</button>
                      </li>
                      <li className>
                        <button>4</button>
                      </li>
                      <li className>
                        <button>5</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fastag_section_five">
              <div className="content_wrapper">
                <div className="title_text">
                  See how FASTag has revolutionised <br />
                  the fleet business
                </div>{" "}
                {Industry.map((data, i) => {
                  return (
                    <>
                      <div className="features_wrapper" key={i}>
                        <div className="content_row row">
                          <div className="col-lg-6 col-sm-12 left_sec">
                            <img src={api + data.image} alt="" />
                          </div>
                          <div className="col-lg-6 col-sm-12 right_sec">
                            <div className="logo_wrapper">
                              <img src={api + data.logo} alt="" />
                            </div>
                            <div className="feature_content">
                              {data.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>{" "}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Fasttag;
