import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Load from "../images/load.gif"

function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);

  const api = "https://api.starroutes.com/";

  const [About, setAbout] = useState([]);

  const [Team, setTeam] = useState([]);

  const [Address, setAddress] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setAddress(res.data.address[0]);
            setAbout(res.data.aboutus[0]);
            setTeam(res.data.team);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      companyName: form.companyName,
      yourEnquiry: form.yourEnquiry,
    };

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              yourEnquiry: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />{" "}
          <div className="container main_container">
            <div className="about_us_container">
              <div className="about_us_content">
                <div className="about-us-section-one">
                  <div className="about-us-content-one">
                    <h2>We are STAR ROUTES </h2>
                    <p>
                      STAR ROUTES , headquartered in Hyderabad, operates three
                      key business verticals at the intersection of Financial
                      Services and Retail – Enterprise, Issuance, and Acquiring.
                      We will soon be launching our fourth vertical focused on
                      Blockchain &amp; Remittance.
                    </p>
                  </div>
                </div>
                <div className="about-us-section-two">
                  <div className="about-us-content-two">
                    <div className="left-sec">
                      <div className="left-sec-content">
                        <h2>{About.about}</h2>
                        <p>{About.description}</p>
                      </div>
                    </div>
                    <div className="right-sec">
                      <div className="right-sec-content">
                        <div className="boxes">
                          <img src={api + About.image1} alt="agile info" />
                          <p>{About.title1}</p>
                        </div>
                        <div className="boxes">
                          <img src={api + About.image2} alt="micro info" />
                          <p>{About.title2}</p>
                        </div>
                        <div className="boxes">
                          <img src={api + About.image3} alt="customer info" />
                          <p>{About.title3}</p>
                        </div>
                        <div className="boxes">
                          <img src={api + About.image4} alt="cloud info" />
                          <p>{About.title4}</p>
                        </div>
                        <div className="boxes">
                          <img src={api + About.image5} alt="delivery info" />
                          <p>{About.title5}</p>
                        </div>
                        <div className="boxes">
                          <img src={api + About.image6} alt="cid circle" />
                          <p>{About.title6}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about-us-section-three">
                  <div className="about-us-content-three">
                    <h2>Our Leadership team</h2>

                    {Team.map((data, i) => {
                      return (
                        <>
                          <div className="row boxes custom-box" key={i}>
                            <div className="col-md-6 col-xs-12 left-sec">
                              <img src={api + data.image} alt="rajesh" />
                            </div>
                            <div className="col-md-6 col-xs-12 right-sec">
                              <h5>{data.name}</h5>
                              <p className="times-text">{data.designation}</p>
                              <p>{data.description}</p>

                              <a href={data.link} target="_blank">
                                {/* <img
                              src="../assets/images/about-us/linkedin-logo.svg"
                              alt="linkedIn logo"
                            /> */}
                                {data.link}
                              </a>
                            </div>
                          </div>
                          <div className="white-line" />
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="about-us-section-four">
                  <div className="about-us-content-four">
                    {/* <a>
                  <button>For AFS, please click here</button>
                </a> */}
                    <div className="license-content">
                      <h2>Licenses &amp; Certifications</h2>
                      <div className="license-flex">
                        <div>
                          <img
                            src="../assets/images/about-us/rupay.png"
                            alt="rupay"
                          />
                        </div>
                        <div>
                          <img
                            src="../assets/images/about-us/reserve-img.png"
                            alt="reserve"
                          />
                        </div>
                        <div>
                          <img
                            src="../assets/images/about-us/pci-dss.png"
                            alt="pci dss"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="section_eight">
              <div className="content_wrapper">
                <div className="title_text">Get in touch with us!</div>
                <div className="form_wrapper">
                  <div className="row">
                    <div className="col-lg-4 left_sec">
                      <div className="contact_details">
                        <div className="location_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="../assets/images/Solid-System-Location.png"
                              alt="wrapper img"
                            />
                          </div>
                          <div className="sub_text">
                            STAR ROUTES Technology(India) Private LimitedUnit
                            No.206, B-Wing,Supreme Business Park,Hiranandani
                            Gardens,Powai, Mumbai-400076
                          </div>
                        </div>
                        <div className="mobile_num_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="../assets/images/Solid-System-Call.png"
                              alt="call image"
                            />
                          </div>
                          <div className="sub_text">
                            <a href="tel:+91-808-081-0099">+91-808-081-0099</a>
                          </div>
                        </div>
                        <div className="mail_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="../assets/images/Solid-System-Mail.png"
                              alt="mail icon"
                            />
                          </div>
                          <div className="sub_text">
                            <a href="mailto:support@STAR ROUTES .com">
                              support@STAR ROUTES .com
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 right_sec">
                      <Form>
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Company Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Company Name"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Phone"
                              />
                            </Form.Group>
                          </Col>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Your Enquiry</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Enquiry"
                              rows={3}
                            />
                          </Form.Group>
                        </Row>

                        <button
                          type="submit"
                          class="cta_btn form_submit_resp "
                          disabled=""
                        >
                          Submit
                        </button>
                      </Form>
                      <div className="respText" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                <div className="section_eight">
                  <div className="content_wrapper">
                    <div className="title_text">Get in touch with us!</div>
                    <div className="form_wrapper">
                      <div className="row">
                        <div className="col-lg-4 left_sec">
                          <div className="contact_details">
                            <div className="location_div flex_div">
                              <div className="img_wrapper">
                                <img
                                  src="assets/images/Solid-System-Location.png"
                                  alt=""
                                />
                              </div>
                              <div className="sub_text">{Address.address}</div>
                            </div>
                            <div className="mobile_num_div flex_div">
                              <div className="img_wrapper">
                                <img
                                  src="assets/images/Solid-System-Call.png"
                                  alt=""
                                />
                              </div>
                              <div className="sub_text">
                                <a href={Address.phone}> {Address.phone}</a>
                              </div>
                            </div>
                            <div className="mail_div flex_div">
                              <div className="img_wrapper">
                                <img
                                  src="assets/images/Solid-System-Mail.png"
                                  alt=""
                                />
                              </div>
                              <div className="sub_text">
                                <a href={Address.email}>{Address.email}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 right_sec">
                          <form
                            class="livquik_contactUs"
                            onSubmit={(e) => {
                              formsubmit(e);
                            }}
                          >
                            <div class="input_row row">
                              <div class="form_group_input col-lg-6">
                                <input
                                  type="text"
                                  class="form_input"
                                  id="name"
                                  placeholder="Name"
                                  autocomplete="new-password"
                                  name="name"
                                  required
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.name}
                                />
                                <p class="errTxt ">Enter name</p>
                              </div>

                              <div class="form_group_input col-lg-6">
                                <input
                                  type="email"
                                  class="form_input "
                                  id="email"
                                  required
                                  placeholder="Email address"
                                  autocomplete="new-password"
                                  name="email"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.email}
                                />
                                <p class="errTxt ">Enter your email</p>
                              </div>
                            </div>
                            <div class="input_row row">
                              <div class="form_group_input col-lg-6">
                                <input
                                  type="text"
                                  class="form_input "
                                  id="companyName"
                                  placeholder="Company name"
                                  autocomplete="new-password"
                                  name="companyName"
                                  required
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.companyName}
                                />
                                <p class="errTxt ">Enter company name</p>
                              </div>
                              <div class="form_group_input col-lg-6">
                                <input
                                  type="number"
                                  class="form_input"
                                  id="phone"
                                  placeholder="Phone number"
                                  maxlength="10"
                                  autocomplete="new-password"
                                  name="phoneNumber"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.phoneNumber}
                                />
                                <p class="errTxt ">Enter your mobile number</p>
                              </div>
                            </div>
                            <div class="input_row row">
                              <div class="form_group_input textarea_input col-lg-12">
                                <textarea
                                  class="form_input"
                                  id="enquiry"
                                  placeholder="Your enquiry"
                                  rows="3"
                                  required
                                  name="yourEnquiry"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.yourEnquiry}
                                ></textarea>
                              </div>
                            </div>
                            <button
                              type="submit"
                              class="form_submit "
                              disabled=""
                            >
                              <img
                                src="../../assets/images/telegram_arrow.svg"
                                alt=""
                              />
                            </button>
                            <button
                              type="submit"
                              class="cta_btn form_submit_resp "
                              disabled=""
                            >
                              Submit
                            </button>
                          </form>
                          <div className="respText" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <ToastContainer />
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default AboutUs;
