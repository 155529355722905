import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import axios from "axios";
import Load from "../images/load.gif"

function Terms() {
  const [Terms, setTerms] = useState([]);

  const [PrivacyPolicy, setPrivacyPolicy] = useState([]);

  const [RefundPolicy, setRefundPolicy] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setTerms(res.data.setting[0].termsAndCondition);
            setPrivacyPolicy(res.data.setting[0].priacyPolicy);
            setRefundPolicy(res.data.setting[0].refundPloicy);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container policy_container">
            <div className="policy_section content_wrapper row">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={4}>
                    <div className="policy_accordion">
                      <div className="accordion_wrapper">
                        <div className="accordion" id="generalFaqs">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header className="accordion">
                                Prepaid Payment Instruments
                              </Accordion.Header>
                              <Accordion.Body>
                                <Nav variant="pills" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey="first">
                                      Terms and Conditions
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="second">
                                      Privacy Policy
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="Third">
                                      Refund Policy
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={8}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="policyContent english">
                          <h2 className="tc_title">
                            <strong>TERMS & CONDITIONS</strong>
                          </h2>
                          <div
                            className="mt-5 tc_title"
                            dangerouslySetInnerHTML={{
                              __html: Terms,
                            }}
                          ></div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        {" "}
                        <div className="policyContent english">
                          <h2 className="tc_title">
                            <strong>Privacy Policy</strong>
                          </h2>
                          <div
                            className="mt-5 tc_title"
                            dangerouslySetInnerHTML={{
                              __html: PrivacyPolicy,
                            }}
                          ></div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Third">
                        {" "}
                        <div className="policyContent english">
                          <h2 className="tc_title">
                            <strong>Refund Policy</strong>
                          </h2>
                          <div
                            className="mt-5 tc_title"
                            dangerouslySetInnerHTML={{
                              __html: RefundPolicy,
                            }}
                          ></div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}


export default Terms;
