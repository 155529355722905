import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Badge from 'react-bootstrap/Badge';
import Load from "../images/load.gif"

function Prepaids() {
  const [Sliders, setSliders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.starroutes.com/";

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setSliders(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container">
            <div className="section_eight" style={{ marginTop: "140px" }}>
              <div className="content_wrapper">
                <div className="title_text">Gps Services</div>
                <div className="form_wrapper">
                  <div className="row">
                    {Sliders.map((data, i) => {
                      return (
                        <div
                          className="col-md-4 text-center pt-5 pb-5 border"
                          key={i}
                        >
                          <h5
                            className="pt-2 pb-1"
                            style={{ fontWeight: "bold" }}
                          >
                             <Badge bg="danger">{data.title}</Badge>
                          </h5>
                          <img
                            src={api + data.image}
                            alt=""
                            width="30%"
                            height="30%"
                          />
                          <p className="pt-3 ">
                            <b>Price : </b>₹ <del>{data.price}</del>
                          </p>
                          <p className="pt-1  pb-2">
                            <b>Offer Price : </b>₹ {data.offerPrice}
                          </p>
                          <p>{data.description}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Prepaids;
