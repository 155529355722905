import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function PamentFail() {
  return (
    <div>
      {" "}
      <Header />
      <div className="container main_container">
        <div className="section_eight" style={{ marginTop: "150px" }}>
          <div className="content_wrapper">
            <div className="form_wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="success-message"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/4436/4436559.png"
                      height="100px"
                    ></img>
                    <h1 className="success-message__title pt-5">
                      Transaction Failed
                    </h1>
                  </div>
                </div>
                <div className="respText" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PamentFail;
