import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import Load from "../images/load.gif";

function OrderFastag() {
  const [form, setform] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const [Files, setFiles] = useState("");
  const [Files1, setFiles1] = useState("");
  const [Files2, setFiles2] = useState("");
  const [Files3, setFiles3] = useState("");
  const [Files4, setFiles4] = useState("");

  const changeHandler = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const changeHandler1 = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const changeHandler2 = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };
  const changeHandler3 = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const changeHandler4 = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles4(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  const HandleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    addUsers();
  };

  const addUsers = () => {
    const dataArray = new FormData();
    dataArray.append("registrationNumber", form.registrationNumber);
    dataArray.append("firstName", form.firstName);
    dataArray.append("lastName", form.lastName);
    dataArray.append("phone", form.phone);
    dataArray.append("email", form.email);
    dataArray.append("dateOfBirth", form.dateOfBirth);
    dataArray.append("pancardNumber", form.pancardNumber);
    dataArray.append("adharcardNumber", form.adharcardNumber);
    dataArray.append("gender", form.gender);
    dataArray.append("vehicleClass", form.vehicleClass);
    dataArray.append("addressLine1", form.addressLine1);
    dataArray.append("addressLine2", form.addressLine2);
    dataArray.append("district", form.district);
    dataArray.append("state", form.state);
    dataArray.append("pincode", form.pincode);

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("adhardcardImage", Files[i]);
    }

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("pancardImage", Files1[i]);
    }

    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("rcFrontImage", Files2[i]);
    }

    for (let i = 0; i < Files3.length; i++) {
      dataArray.append("rcBackImage", Files3[i]);
    }

    for (let i = 0; i < Files4.length; i++) {
      dataArray.append("driverImage", Files4[i]);
    }

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/vechile/addvechile",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            window.location.href = res.data.response;
            clearInput();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const clearInput = () => {
    setform({
      registrationNumber: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      dateOfBirth: "",
      idProofDocument: "",
      documentNumber: "",
      gender: "",
      vehicleClass: "",
      houseNumber: "",
      buildingName: "",
      addressLine1: "",
      addressLine2: "",
      district: "",
      state: "",
      pincode: "",
    });
    setFiles({
      Image: "",
    });
    setFiles1({
      Image: "",
    });
    setFiles2({
      Image: "",
    });
    setFiles3({
      Image: "",
    });
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container">
            <Form
              onSubmit={HandleSubmit}
              style={{ marginTop: "180px" }}
              className="hdd"
            >
              <Row className="m-5 Order-Section ">
                <div className="align-items-center border-bottom d-flex justify-content-between my-3 py-1">
                  <h4 className="mb-3 mt-3  text-dark ">
                    <b>Vehicle Details</b>
                  </h4>

                  <div>
                    <span
                      className="bg-secondary font-italic px-2 py-1 rounded-pill text-light "
                      style={{ float: "right" }}
                    >
                      Price : ₹450
                    </span>
                  </div>
                </div>

                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Vehicle Registration No / Chassis Number
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="registrationNumber"
                        value={form.registrationNumber}
                        required
                        pattern="[A-Za-z0-9]{2,10}"
                        placeholder="Enter Vehicle Registration No"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Label>
                      Vehicle Class<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      size="sm"
                      required
                      value={form.vehicleClass}
                      aria-label="Default select example"
                      className="mb-3"
                      name="vehicleClass"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    >
                      <option value=""> ---SELECT VEHICLE CLASS---</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR MINI LCV">
                        TATA ACE AND SIMILAR MINI LCV
                      </option>
                      <option value="Mini Bus">Mini Bus</option>
                      <option value="Bus 3 axle">Bus 3 axle</option>
                      <option value="Truck 3 Axle">Truck 3 Axle</option>
                      <option value="Bus 2 Axle">Bus 2 Axle</option>
                      <option value="Truck 2 Axle">Truck 2 Axle</option>
                      <option value="Truck 4-Axle">Truck 4-Axle</option>
                      <option value="Truck 5- Axle">Truck 5- Axle</option>
                      <option value="Truck 6- Axle">Truck 6- Axle</option>
                      <option value="Light Commercial vehicle 2-axle">
                        Light Commercial vehicle 2-axle
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR MINI LCV">
                        TATA ACE AND SIMILAR MINI LCV
                      </option>
                      <option value="Light Commercial vehicle 2-axle">
                        Light Commercial vehicle 2-axle
                      </option>
                      <option value="Mini Bus">Mini Bus</option>
                      <option value="Bus 3-axle">Bus 3-axle</option>
                      <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                      <option value="Bus- 2 axle">Bus- 2 axle</option>
                      <option value="Truck- 2 axle">Truck- 2 axle</option>
                      <option value="Truck-4 Axle">Truck-4 Axle</option>
                      <option value="Truck- 5 axle">Truck- 5 axle</option>
                      <option value="Truck- 6 axle">Truck- 6 axle</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV (Nilsec Min 150+ tag cost 100)">
                        TATA ACE AND SIMILAR LMV (Nilsec Min 150+ tag cost 100)
                      </option>
                      <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="Light Commercial vehicle 2-axle">
                        Light Commercial vehicle 2-axle
                      </option>
                      <option value="Three Axle Commercial Vehicle">
                        Three Axle Commercial Vehicle
                      </option>
                      <option value="Bus 3-axle">Bus 3-axle</option>
                      <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                      <option value="Bus- 2 axle">Bus- 2 axle</option>
                      <option value="Truck- 2 axle">Truck- 2 axle</option>
                      <option value="Truck-4 Axle">Truck-4 Axle</option>
                      <option value="Truck-5 Axle">Truck-5 Axle</option>
                      <option value="Truck-6 Axle">Truck-6 Axle</option>
                      <option value="7 OR MORE AXLE">7 OR MORE AXLE</option>
                      <option value="hEAVY CONSTRUCTION  MACHINERY">
                        hEAVY CONSTRUCTION MACHINERY
                      </option>
                      <option value="HPCL KYC CAR/JEEP/VAN (150+50)">
                        HPCL KYC CAR/JEEP/VAN (150+50)
                      </option>
                      <option value="HPCL KYC TATA ACE AND LCV">
                        HPCL KYC TATA ACE AND LCV
                      </option>
                      <option value="HPCL KYC CAR/JEEP/VAN">
                        HPCL KYC CAR/JEEP/VAN
                      </option>
                      <option value="HPCL NKYC CAR/JEEP/VAN">
                        HPCL NKYC CAR/JEEP/VAN
                      </option>
                      <option value="HPCL NKYC TATA ACE AND LCV">
                        HPCL NKYC TATA ACE AND LCV
                      </option>
                      <option value="Nilsec NKYC Car/Jeep/Van/">
                        Nilsec NKYC Car/Jeep/Van/
                      </option>
                      <option value="Nilsec NKYC TATA ACE AND LCV">
                        Nilsec NKYC TATA ACE AND LCV
                      </option>
                      <option value="Local Pass Nil Price Car/Jeep/Van/Mini Light Commercial Vehicle">
                        Local Pass Nil Price Car/Jeep/Van/Mini Light Commercial
                        Vehicle
                      </option>
                      <option value="Local Pass Nil Price TATA">
                        Local Pass Nil Price TATA
                      </option>
                      <option value="Local Pass Nil Price Light Commercial Vehicle">
                        Local Pass Nil Price Light Commercial Vehicle
                      </option>
                      <option value="HPCL NKYC CAR/JEEP/VAN">
                        HPCL NKYC CAR/JEEP/VAN
                      </option>
                      <option value="HPCL NKYC TATA ACE AND LCV">
                        HPCL NKYC TATA ACE AND LCV
                      </option>
                      <option value="Nilsec NKYC Car/Jeep/Van/Mini">
                        Nilsec NKYC Car/Jeep/Van/Mini
                      </option>
                      <option value="NILSEC NON KYC TATA ACE">
                        NILSEC NON KYC TATA ACE
                      </option>
                      <option value="Nilsec NKYC Car/Jeep/Van/Mini">
                        Nilsec NKYC Car/Jeep/Van/Mini
                      </option>
                      <option value="NILSEC NON KYC TATA ACE">
                        NILSEC NON KYC TATA ACE
                      </option>
                      <option value="HPCL NKYC CAR/JEEP/VAN">
                        HPCL NKYC CAR/JEEP/VAN
                      </option>
                      <option value="7 or More axle">7 or More axle</option>
                      <option value="Heavy Construction  machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="[Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="HPCL KYC CAR/JEEP/VAN (200+50)">
                        HPCL KYC CAR/JEEP/VAN (200+50)
                      </option>
                      <option value="HPCL KYC TATA ACE AND LCV">
                        HPCL KYC TATA ACE AND LCV
                      </option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="LIGHT COMMERCIAL VEHICLE">
                        LIGHT COMMERCIAL VEHICLE
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="Nilsec NKYC Car/Jeep/Van/">
                        Nilsec NKYC Car/Jeep/Van/
                      </option>
                      <option value="Nilsec NKYC TATA ACE AND LCV">
                        Nilsec NKYC TATA ACE AND LCV
                      </option>
                      <option value="CAR/JEEP/VAN(100+0+100 KYC)">
                        CAR/JEEP/VAN(100+0+100 KYC)
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV(100+0+100 KYC)">
                        TATA ACE AND SIMILAR LMV(100+0+100 KYC)
                      </option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN- (100+0+50)">
                        CAR/JEEP/VAN- (100+0+50)
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV - (100+0+50)">
                        TATA ACE AND SIMILAR LMV - (100+0+50)
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR MINI LCV">
                        TATA ACE AND SIMILAR MINI LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="CAR/JEEP/VAN- HPCL(100+0+50)">
                        CAR/JEEP/VAN- HPCL(100+0+50)
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV-HPCL(100+0+50)">
                        TATA ACE AND SIMILAR LMV-HPCL(100+0+50)
                      </option>
                      <option value="CAR/JEEP/VAN-(100+0+250)">
                        CAR/JEEP/VAN-(100+0+250)
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV -(100+0+250)">
                        TATA ACE AND SIMILAR LMV -(100+0+250)
                      </option>
                      <option value="CAR/JEEP/VAN- (100+0+150)">
                        CAR/JEEP/VAN- (100+0+150)
                      </option>
                      <option value="TATA ACE AND SIMILAR LMV - (100+0+150)">
                        TATA ACE AND SIMILAR LMV - (100+0+150)
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV-">
                        TATA ACE AND SIMILAR LMV-
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="tRUCK- 2 AXLE">tRUCK- 2 AXLE</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LMV">
                        TATA ACE AND SIMILAR LMV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND LIGHT SIMILAR">
                        TATA ACE AND LIGHT SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMLAR LCV">
                        TATA ACE AND SIMLAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMLAR LCV">
                        TATA ACE AND SIMLAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR PRODUCT">
                        TATA ACE SIMILAR PRODUCT
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMLAR LCV">
                        TATA ACE AND SIMLAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="Bus 3-axle">Bus 3-axle</option>
                      <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                      <option value="Bus 2 Axle">Bus 2 Axle</option>
                      <option value="Truck- 2 axle">Truck- 2 axle</option>
                      <option value="Truck-4 Axle">Truck-4 Axle</option>
                      <option value="Truck- 5 axle">Truck- 5 axle</option>
                      <option value="Truck- 6 axle">Truck- 6 axle</option>
                      <option value="[object Object]"></option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="[object Object]"></option>
                      <option value="Truck 4 axle">Truck 4 axle</option>
                      <option value="car jeep van">car jeep van</option>
                      <option value="CAR JEEP VAN">CAR JEEP VAN</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                      <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE SIMILAR LCV">
                        TATA ACE SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="Truck-4 Axle">Truck-4 Axle</option>
                      <option value="Truck- 5 axle">Truck- 5 axle</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK 4 AXLE">TRUCK 4 AXLE</option>
                      <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="7 or more Axle">7 or more Axle</option>
                      <option value="Heavy Construction machinery">
                        Heavy Construction machinery
                      </option>
                      <option value="Earth Moving Machinery">
                        Earth Moving Machinery
                      </option>
                      <option value="LIGHT COMMERICAL VEHICLE">
                        LIGHT COMMERICAL VEHICLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                      <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                      <option value="TRUCK 4 AXLE">TRUCK 4 AXLE</option>
                      <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                      <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                      <option value="[object Object]"></option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS 2 AXLE">BUS 2 AXLE</option>
                      <option value="TRUCK-2 AXLE">TRUCK-2 AXLE</option>
                      <option value="TRUCK-4 AXLE">TRUCK-4 AXLE</option>
                      <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                        LIGHT COMMERCIAL VEHICLE 2-AXLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                        LIGHT COMMERCIAL VEHICLE 2-AXLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                      <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                      <option value="BUS 2 AXLE">BUS 2 AXLE</option>
                      <option value="TRUCK-2 AXLE">TRUCK-2 AXLE</option>
                      <option value="TRUCK-4 AXLE">TRUCK-4 AXLE</option>
                      <option value="TRUCK-5 AXLE">TRUCK-5 AXLE</option>
                      <option value="TRUCK-6 AXLE">TRUCK-6 AXLE</option>
                      <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                        LIGHT COMMERCIAL VEHICLE 2-AXLE
                      </option>
                      <option value="MINI BUS">MINI BUS</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR LCV">
                        TATA ACE AND SIMILAR LCV
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                      <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                      <option value="TATA ACE AND SIMILAR">
                        TATA ACE AND SIMILAR
                      </option>
                    </Form.Select>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        First Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="firstName"
                        pattern="[A-Za-z\s]{2,50}"
                        value={form.firstName}
                        required
                        placeholder="Enter First Name"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Last Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="lastName"
                        pattern="[A-Za-z\s]{2,50}"
                        value={form.lastName}
                        required
                        placeholder="Enter Last Name"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Mobile No<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="phone"
                        pattern="^[5-9]\d{9}$"
                        minLength="10"
                        maxLength="10"
                        value={form.phone}
                        required
                        placeholder="Enter Mobile No"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="email"
                        name="email"
                        value={form.email}
                        required
                        placeholder="Enter Email"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Label>
                      Gender<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      size="sm"
                      value={form.gender}
                      name="gender"
                      required
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      aria-label="Default select example"
                      className="mb-3"
                    >
                      <option value=""> Select </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Address 1<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="addressLine1"
                        value={form.addressLine1}
                        required
                        placeholder="Enter Address Line 1"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Address 2<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="addressLine2"
                        value={form.addressLine2}
                        required
                        placeholder="Enter Address Line 2"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        City / District<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="district"
                        value={form.district}
                        required
                        placeholder="Enter District"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Pincode<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="pincode"
                        value={form.pincode}
                        required
                        pattern="[0-9]{6}"
                        maxlength="6"
                        minLength="6"
                        placeholder="Enter Pincode"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        State<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="state"
                        value={form.state}
                        required
                        placeholder="Enter State"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Aadhaar Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        pattern="\d{12}"
                        name="adharcardNumber"
                        value={form.adharcardNumber}
                        required
                        placeholder="Aadhaar Number"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      Aadhaar Card Image
                      <Form.Label>
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        required
                        placeholder="Enter image"
                        name="Image"
                        value={Files.Image}
                        onChange={changeHandler}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Pan Card Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="pancardNumber"
                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                        value={form.pancardNumber}
                        required
                        placeholder="Pan Card Number"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      Pan Card Image
                      <Form.Label>
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        placeholder="Enter image"
                        name="Image"
                        required
                        value={Files1.Image}
                        onChange={changeHandler1}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Date of Birth <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="Date"
                        name="dateOfBirth"
                        value={form.dateOfBirth}
                        required
                        placeholder="Date of Birth"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      Rc Front Copy
                      <Form.Label>
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        placeholder="Enter image"
                        name="Image"
                        required
                        value={Files2.Image}
                        onChange={changeHandler2}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      Rc Back Copy
                      <Form.Label>
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        required
                        placeholder="Enter image"
                        name="Image"
                        value={Files3.Image}
                        onChange={changeHandler3}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      Driver Image
                      <Form.Label>
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        placeholder="Enter image"
                        name="Image"
                        required
                        value={Files4.Image}
                        onChange={changeHandler4}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <div style={{ float: "right" }}>
                      <Button
                        variant="primary"
                        className="mt-4 mb-5 cta_btn"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>

                {/* <Col md={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Vehicle Registration No
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="registrationNumber"
                      value={form.registrationNumber}
                      required
                      pattern="[A-Za-z0-9]{2,10}"
                      placeholder="Enter Vehicle Registration No"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      First Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="firstName"
                      pattern="[A-Za-z\s]{2,50}"
                      value={form.firstName}
                      required
                      placeholder="Enter First Name"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Last Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="lastName"
                      pattern="[A-Za-z\s]{2,50}"
                      value={form.lastName}
                      required
                      placeholder="Enter Last Name"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Mobile No<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="phone"
                      pattern="^[5-9]\d{9}$"
                      minLength="10"
                      maxLength="10"
                      value={form.phone}
                      required
                      placeholder="Enter Mobile No"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="email"
                      name="email"
                      value={form.email}
                      required
                      placeholder="Enter Email"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Date of Birth <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="Date"
                      name="dateOfBirth"
                      value={form.dateOfBirth}
                      required
                      placeholder="Date of Birth"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    Upload Id proof Front
                    <Form.Label>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      placeholder="Enter image"
                      name="Image"
                      required
                      value={Files.Image}
                      onChange={changeHandler}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    Upload Rc Back
                    <Form.Label>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      required
                      placeholder="Enter image"
                      name="Image"
                      value={Files3.Image}
                      onChange={changeHandler3}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Label>
                    Id Proof document <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    value={form.idProofDocument}
                    aria-label="Default select example"
                    className="mb-3"
                    required
                    name="idProofDocument"
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  >
                    <option value="">---CHOOSE THE DOCUMENT type---</option>
                    <option value="Aadhar">Aadhar</option>
                    <option value="Voter Id">Voter Id</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Passport">Passport</option>
                  </Form.Select>

                  

                  <Form.Label>
                    Vehicle Class<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    required
                    value={form.vehicleClass}
                    aria-label="Default select example"
                    className="mb-3"
                    name="vehicleClass"
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  >
                    <option value=""> ---SELECT VEHICLE CLASS---</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR MINI LCV">
                      TATA ACE AND SIMILAR MINI LCV
                    </option>
                    <option value="Mini Bus">Mini Bus</option>
                    <option value="Bus 3 axle">Bus 3 axle</option>
                    <option value="Truck 3 Axle">Truck 3 Axle</option>
                    <option value="Bus 2 Axle">Bus 2 Axle</option>
                    <option value="Truck 2 Axle">Truck 2 Axle</option>
                    <option value="Truck 4-Axle">Truck 4-Axle</option>
                    <option value="Truck 5- Axle">Truck 5- Axle</option>
                    <option value="Truck 6- Axle">Truck 6- Axle</option>
                    <option value="Light Commercial vehicle 2-axle">
                      Light Commercial vehicle 2-axle
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR MINI LCV">
                      TATA ACE AND SIMILAR MINI LCV
                    </option>
                    <option value="Light Commercial vehicle 2-axle">
                      Light Commercial vehicle 2-axle
                    </option>
                    <option value="Mini Bus">Mini Bus</option>
                    <option value="Bus 3-axle">Bus 3-axle</option>
                    <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                    <option value="Bus- 2 axle">Bus- 2 axle</option>
                    <option value="Truck- 2 axle">Truck- 2 axle</option>
                    <option value="Truck-4 Axle">Truck-4 Axle</option>
                    <option value="Truck- 5 axle">Truck- 5 axle</option>
                    <option value="Truck- 6 axle">Truck- 6 axle</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV (Nilsec Min 150+ tag cost 100)">
                      TATA ACE AND SIMILAR LMV (Nilsec Min 150+ tag cost 100)
                    </option>
                    <option value="CAAR/JEEP/VAN">CAAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="Light Commercial vehicle 2-axle">
                      Light Commercial vehicle 2-axle
                    </option>
                    <option value="Three Axle Commercial Vehicle">
                      Three Axle Commercial Vehicle
                    </option>
                    <option value="Bus 3-axle">Bus 3-axle</option>
                    <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                    <option value="Bus- 2 axle">Bus- 2 axle</option>
                    <option value="Truck- 2 axle">Truck- 2 axle</option>
                    <option value="Truck-4 Axle">Truck-4 Axle</option>
                    <option value="Truck-5 Axle">Truck-5 Axle</option>
                    <option value="Truck-6 Axle">Truck-6 Axle</option>
                    <option value="7 OR MORE AXLE">7 OR MORE AXLE</option>
                    <option value="hEAVY CONSTRUCTION  MACHINERY">
                      hEAVY CONSTRUCTION MACHINERY
                    </option>
                    <option value="HPCL KYC CAR/JEEP/VAN (150+50)">
                      HPCL KYC CAR/JEEP/VAN (150+50)
                    </option>
                    <option value="HPCL KYC TATA ACE AND LCV">
                      HPCL KYC TATA ACE AND LCV
                    </option>
                    <option value="HPCL KYC CAR/JEEP/VAN">
                      HPCL KYC CAR/JEEP/VAN
                    </option>
                    <option value="HPCL NKYC CAR/JEEP/VAN">
                      HPCL NKYC CAR/JEEP/VAN
                    </option>
                    <option value="HPCL NKYC TATA ACE AND LCV">
                      HPCL NKYC TATA ACE AND LCV
                    </option>
                    <option value="Nilsec NKYC Car/Jeep/Van/">
                      Nilsec NKYC Car/Jeep/Van/
                    </option>
                    <option value="Nilsec NKYC TATA ACE AND LCV">
                      Nilsec NKYC TATA ACE AND LCV
                    </option>
                    <option value="Local Pass Nil Price Car/Jeep/Van/Mini Light Commercial Vehicle">
                      Local Pass Nil Price Car/Jeep/Van/Mini Light Commercial
                      Vehicle
                    </option>
                    <option value="Local Pass Nil Price TATA">
                      Local Pass Nil Price TATA
                    </option>
                    <option value="Local Pass Nil Price Light Commercial Vehicle">
                      Local Pass Nil Price Light Commercial Vehicle
                    </option>
                    <option value="HPCL NKYC CAR/JEEP/VAN">
                      HPCL NKYC CAR/JEEP/VAN
                    </option>
                    <option value="HPCL NKYC TATA ACE AND LCV">
                      HPCL NKYC TATA ACE AND LCV
                    </option>
                    <option value="Nilsec NKYC Car/Jeep/Van/Mini">
                      Nilsec NKYC Car/Jeep/Van/Mini
                    </option>
                    <option value="NILSEC NON KYC TATA ACE">
                      NILSEC NON KYC TATA ACE
                    </option>
                    <option value="Nilsec NKYC Car/Jeep/Van/Mini">
                      Nilsec NKYC Car/Jeep/Van/Mini
                    </option>
                    <option value="NILSEC NON KYC TATA ACE">
                      NILSEC NON KYC TATA ACE
                    </option>
                    <option value="HPCL NKYC CAR/JEEP/VAN">
                      HPCL NKYC CAR/JEEP/VAN
                    </option>
                    <option value="7 or More axle">7 or More axle</option>
                    <option value="Heavy Construction  machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="[Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="HPCL KYC CAR/JEEP/VAN (200+50)">
                      HPCL KYC CAR/JEEP/VAN (200+50)
                    </option>
                    <option value="HPCL KYC TATA ACE AND LCV">
                      HPCL KYC TATA ACE AND LCV
                    </option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="LIGHT COMMERCIAL VEHICLE">
                      LIGHT COMMERCIAL VEHICLE
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="Nilsec NKYC Car/Jeep/Van/">
                      Nilsec NKYC Car/Jeep/Van/
                    </option>
                    <option value="Nilsec NKYC TATA ACE AND LCV">
                      Nilsec NKYC TATA ACE AND LCV
                    </option>
                    <option value="CAR/JEEP/VAN(100+0+100 KYC)">
                      CAR/JEEP/VAN(100+0+100 KYC)
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV(100+0+100 KYC)">
                      TATA ACE AND SIMILAR LMV(100+0+100 KYC)
                    </option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN- (100+0+50)">
                      CAR/JEEP/VAN- (100+0+50)
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV - (100+0+50)">
                      TATA ACE AND SIMILAR LMV - (100+0+50)
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR MINI LCV">
                      TATA ACE AND SIMILAR MINI LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="CAR/JEEP/VAN- HPCL(100+0+50)">
                      CAR/JEEP/VAN- HPCL(100+0+50)
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV-HPCL(100+0+50)">
                      TATA ACE AND SIMILAR LMV-HPCL(100+0+50)
                    </option>
                    <option value="CAR/JEEP/VAN-(100+0+250)">
                      CAR/JEEP/VAN-(100+0+250)
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV -(100+0+250)">
                      TATA ACE AND SIMILAR LMV -(100+0+250)
                    </option>
                    <option value="CAR/JEEP/VAN- (100+0+150)">
                      CAR/JEEP/VAN- (100+0+150)
                    </option>
                    <option value="TATA ACE AND SIMILAR LMV - (100+0+150)">
                      TATA ACE AND SIMILAR LMV - (100+0+150)
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV-">
                      TATA ACE AND SIMILAR LMV-
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK - 5 AXLE">TRUCK - 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="tRUCK- 2 AXLE">tRUCK- 2 AXLE</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LMV">
                      TATA ACE AND SIMILAR LMV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND LIGHT SIMILAR">
                      TATA ACE AND LIGHT SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMLAR LCV">
                      TATA ACE AND SIMLAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMLAR LCV">
                      TATA ACE AND SIMLAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR PRODUCT">
                      TATA ACE SIMILAR PRODUCT
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMLAR LCV">
                      TATA ACE AND SIMLAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="Bus 3-axle">Bus 3-axle</option>
                    <option value="Truck- 3 Axle">Truck- 3 Axle</option>
                    <option value="Bus 2 Axle">Bus 2 Axle</option>
                    <option value="Truck- 2 axle">Truck- 2 axle</option>
                    <option value="Truck-4 Axle">Truck-4 Axle</option>
                    <option value="Truck- 5 axle">Truck- 5 axle</option>
                    <option value="Truck- 6 axle">Truck- 6 axle</option>
                    <option value="[object Object]"></option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="[object Object]"></option>
                    <option value="Truck 4 axle">Truck 4 axle</option>
                    <option value="car jeep van">car jeep van</option>
                    <option value="CAR JEEP VAN">CAR JEEP VAN</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK- 5 AXLE">TRUCK- 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK- 4 AXLE">TRUCK- 4 AXLE</option>
                    <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE SIMILAR LCV">
                      TATA ACE SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="Truck-4 Axle">Truck-4 Axle</option>
                    <option value="Truck- 5 axle">Truck- 5 axle</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK 4 AXLE">TRUCK 4 AXLE</option>
                    <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="7 or more Axle">7 or more Axle</option>
                    <option value="Heavy Construction machinery">
                      Heavy Construction machinery
                    </option>
                    <option value="Earth Moving Machinery">
                      Earth Moving Machinery
                    </option>
                    <option value="LIGHT COMMERICAL VEHICLE">
                      LIGHT COMMERICAL VEHICLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS- 2 AXLE">BUS- 2 AXLE</option>
                    <option value="TRUCK- 2 AXLE">TRUCK- 2 AXLE</option>
                    <option value="TRUCK 4 AXLE">TRUCK 4 AXLE</option>
                    <option value="TRUCK 5 AXLE">TRUCK 5 AXLE</option>
                    <option value="TRUCK 6 AXLE">TRUCK 6 AXLE</option>
                    <option value="[object Object]"></option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS 2 AXLE">BUS 2 AXLE</option>
                    <option value="TRUCK-2 AXLE">TRUCK-2 AXLE</option>
                    <option value="TRUCK-4 AXLE">TRUCK-4 AXLE</option>
                    <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                      LIGHT COMMERCIAL VEHICLE 2-AXLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                      LIGHT COMMERCIAL VEHICLE 2-AXLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="BUS 3 AXLE">BUS 3 AXLE</option>
                    <option value="TRUCK 3 AXLE">TRUCK 3 AXLE</option>
                    <option value="BUS 2 AXLE">BUS 2 AXLE</option>
                    <option value="TRUCK-2 AXLE">TRUCK-2 AXLE</option>
                    <option value="TRUCK-4 AXLE">TRUCK-4 AXLE</option>
                    <option value="TRUCK-5 AXLE">TRUCK-5 AXLE</option>
                    <option value="TRUCK-6 AXLE">TRUCK-6 AXLE</option>
                    <option value="LIGHT COMMERCIAL VEHICLE 2-AXLE">
                      LIGHT COMMERCIAL VEHICLE 2-AXLE
                    </option>
                    <option value="MINI BUS">MINI BUS</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR LCV">
                      TATA ACE AND SIMILAR LCV
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                    <option value="CAR/JEEP/VAN">CAR/JEEP/VAN</option>
                    <option value="TATA ACE AND SIMILAR">
                      TATA ACE AND SIMILAR
                    </option>
                  </Form.Select>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      House No<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="houseNumber"
                      value={form.houseNumber}
                      required
                      placeholder="Enter House No"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Building Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="buildingName"
                      value={form.buildingName}
                      required
                      placeholder="Enter Building Name"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pincode<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="pincode"
                      value={form.pincode}
                      required
                      pattern="[0-9]{6}"
                      maxlength="6"
                      minLength="6"
                      placeholder="Enter Pincode"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    Upload Id proof Back
                    <Form.Label>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      placeholder="Enter image"
                      name="Image"
                      required
                      value={Files1.Image}
                      onChange={changeHandler1}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      City<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="City"
                      value={form.City}
                      required
                      placeholder="Enter City"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      District<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="district"
                      value={form.district}
                      required
                      placeholder="Enter District"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      State<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="state"
                      value={form.state}
                      required
                      placeholder="Enter State"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Document Number <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="documentNumber"
                      value={form.documentNumber}
                      required
                      placeholder="Document Number"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Address Line 1<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="addressLine1"
                      value={form.addressLine1}
                      required
                      placeholder="Enter Address Line 1"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Address Line 2<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="addressLine2"
                      value={form.addressLine2}
                      required
                      placeholder="Enter Address Line 2"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    Upload RC front
                    <Form.Label>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      placeholder="Enter image"
                      name="Image"
                      required
                      value={Files2.Image}
                      onChange={changeHandler2}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <div style={{ float: "right" }}>
                    <Button
                      variant="primary"
                      className="mt-4 mb-5 cta_btn"
                      type="submit"
                      // onClick={handleTestPayment}
                    >
                      Submit
                    </Button>
                  </div>
                </Col> */}
              </Row>
            </Form>
            <ToastContainer />
            {/* </div> */}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default OrderFastag;
