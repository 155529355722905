import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Load from "../images/load.gif"

function CorporateGovernance() {
  const api = "https://api.starroutes.com/";

  const [Corporate, setCorporate] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setCorporate(res.data.corporateGovernance);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="coorporate-main-container">
            <div className="coorporate-main-section">
              <div className="coorporate-main-content">
                <div className="title_text">Corporate Governance</div>

                <div className="pdf-flyers-container">
                  <div className="pdf-flyers-mainbox">
                    {Corporate.map((data, i) => {
                      return (
                        <>
                          <div className="pdf-flyers-box" key={i}>
                            <div className="pdf-icon">
                              <img
                                src="../assets/images/pdf-icon.svg"
                                alt="pdf-flyers"
                              />
                            </div>
                            <a href={api + data.pdf} target="_blank">
                              {data.title.slice(0, 10)}...
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="supportSection">
                  <p>
                    For further queries, please <br />
                    contact :
                    <a href="starroutes@gmail.com">starroutes@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <Footer />
        </>
      )}
    </div>
  );
}

export default CorporateGovernance;
