import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Load from "../images/load.gif"

function Quikwallet() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 500);
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />{" "}
          <div className="container main_container quikwallet_section">
            <div className="qw_section_one">
              <div className="content_wrapper">
                <div className="title_text">
                  One App. One Wallet. <br />
                  Multiple benefits.
                </div>
                <div className="sub_text">
                  Link your digital wallet with a UPI-enabled application for
                  seamless management, control, and tracking day-to-day
                  transactions on the go. Experience real-time handling of all
                  your card operations through a white-labeled wallet app.
                </div>
                <div className="cta_btn">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.STAR ROUTES.quikwallet"
                  >
                    Get the app
                  </a>
                </div>
              </div>
            </div>
            <div className="qw_section_two">
              <div className="content_wrapper row">
                <div className="col-lg-6 col-sm-12 left_sec">
                  <div className="title_text">
                    Complete card control, now <br />
                    with UPI as wel
                  </div>
                  <div className="sub_text">
                    Manage your card including setting transaction limits, card
                    blocking, etc.
                  </div>
                  <div className="cta_btn">
                    <a
                      target="_blank"
                      href="#"
                    >
                    Book A Demo
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 right_sec">
                  <img src="../assets/images/QW_App_Image.png" alt="" />
                </div>
              </div>
            </div>
            <div className="qw_section_three">
              <div className="content_wrapper">
                <div className="card_wrapper">
                  <div className="cards">
                    <div className="image_div">
                      <img
                        src="../assets/images/QW_Quick_Issuance.svg"
                        alt=""
                      />
                    </div>
                    <div className="card_name">
                      Quick <br />
                      Issuance
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img
                        src="../assets/images/QW_Seamless_Integ.svg"
                        alt=""
                      />
                    </div>
                    <div className="card_name">
                      Seamless <br />
                      Integration
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/QW_VKC.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Integrated vKYC stack <br />
                      for 100% digital KYC
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/QW_VKYC.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Ability to issue with <br />
                      minimum and full KYC&nbsp;
                    </div>
                  </div>
                </div>
                <div className="card_wrapper">
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/QW_UPI.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Issuance of UPI handles <br />
                      through QW App
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img
                        src="../assets/images/QW_Virtual_account.svg"
                        alt=""
                      />
                    </div>
                    <div className="card_name">
                      Instant virtual account <br />
                      &amp; card creation
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img src="../assets/images/QW_Sub_wallet.svg" alt="" />
                    </div>
                    <div className="card_name">
                      Create sub-wallets linked <br />
                      to a central account
                    </div>
                  </div>
                  <div className="cards">
                    <div className="image_div">
                      <img
                        src="../assets/images/QW_Online_Payment.svg"
                        alt=""
                      />
                    </div>
                    <div className="card_name">
                      Available for online <br />
                      and offline payments
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="qw_section_four">
              <div className="content_wrapper">
                <div className="logo_wrapper">
                  <div className="logo_div">
                    <img src="../assets/images/QW_ihmcl_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_nhai_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_netc_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_rupay_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_hp_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_upi_gray.svg" alt="" />
                  </div>
                  <div className="logo_div">
                    <img src="../assets/images/QW_fastag_gray.svg" alt="" />
                  </div>
                </div>
                <div className="info_text">
                  Part of National Electronic Toll Collection (NETC) initiative
                  rolled out by NPCI under the guidelines of NHAI and IHMCL.
                </div>
              </div>
            </div>
          </div>{" "}
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default Quikwallet;
