import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Load from "../images/load.gif"

function ViewBlog() {
  const [blog, setblog] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas3();
  }, []);

  const getid = sessionStorage.getItem("blogid");

  const datas3 = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getblogbyid",
        { _id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setblog(res.data.data);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <Container fluid className="mt-5" style={{ background: "white" }}>
            <Row className="mt-5 m-5">
              <div id="page-container">
                <div id="et-boc" className="et-boc">
                  <div id="et-main-area">
                    <div id="main-content">
                      <div className="et-l et-l--body">
                        <div className="et_builder_inner_content et_pb_gutters3">
                          <div className="et_pb_section et_pb_section_0_tb_body et_pb_with_background et_section_regular">
                            <div className="et_pb_row et_pb_row_0_tb_body">
                              <div className="et_pb_column et_pb_column_4_4 et_pb_column_0_tb_body et_pb_css_mix_blend_mode_passthrough et-last-child">
                                <div className="et_pb_module et_pb_post_title et_pb_post_title_0_tb_body et_pb_bg_layout_light et_pb_text_align_left">
                                  <div className="et_pb_title_featured_container">
                                    <span className="et_pb_image_wrap">
                                      <Col md={12}>
                                        <img
                                          src="assets/b4.jpg"
                                          height="450px"
                                          width="100%"
                                          alt=""
                                        />
                                      </Col>
                                    </span>
                                  </div>
                                  <div className="et_pb_title_container" />
                                </div>
                              </div>
                            </div>

                            <div className="et_pb_row et_pb_row_2_tb_body mb-5">
                              <div className="et_pb_column et_pb_column_4_4 et_pb_column_2_tb_body et_pb_css_mix_blend_mode_passthrough et-last-child">
                                <div className="et_pb_module et_pb_post_content et_pb_post_content_0_tb_body">
                                  <h3 className="mt-5">{blog.title}</h3>
                                  <div style={{ width: "100%" }}>
                                    <div
                                      className="mt-5"
                                      dangerouslySetInnerHTML={{
                                        __html: blog.description,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </div>
  );
}

export default ViewBlog;
