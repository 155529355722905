import React from "react";
import { NavLink } from "react-router-dom";
import Load from "../images/load.gif";

function Footer() {
  return (
    <div>
      <div className="footer container-fluid">
        <div className="footer_wrapper">
          <div className="top_sec">
            <img src="assets/images/logo.png" alt="STAR ROUTES Logo" />
            <div className="sub_text">
              STAR ROUTES is a technology-first fintech offering agile solutions
              across enterprise, issuing, acquiring. Founded in 2012, STAR
              ROUTES has evolved into a trusted partner for offline, online
              merchants, digitally savvy millennials, and fintech organizations
              around the world.
            </div>
            <div className="social_media_wrapper">
              <div className="logo_wrapper">
                <a className="social_link" href="https://www.facebook.com">
                  <img src="assets/images/Facebook_white.svg" alt="Facebook" />
                </a>
              </div>
              <div className="logo_wrapper">
                <a className="social_link" href="https://www.instagram.com">
                  <img
                    src="assets/images/Instagram_white.svg"
                    alt="Instagram"
                  />
                </a>
              </div>
              <div className="logo_wrapper">
                <a className="social_link" href="https://twitter.com">
                  <img src="assets/images/Twitter_white.svg" alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="policy_wrapper">
              <div className="policy_link">
                <NavLink to="/Terms">
                  <a>T&amp;C + policies</a>
                </NavLink>
              </div>
              <div className="policy_link">
                <NavLink to="/CorporateGovernance">
                  <a>Corporate Governance</a>
                </NavLink>
              </div>
              <div className="policy_link">
                <NavLink to="/FAQ">
                  {" "}
                  <a>FAQ</a>
                </NavLink>
              </div>
              <div className="policy_link">
                <NavLink to="/QuikWalletFAQ">
                  {" "}
                  <a>QuikWallet FAQ</a>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="bottom_sec">
            <div className="row" style={{ color: "white" }}>
              <div className="col-md-6" style={{ float: "left" }}>
                Copyright © 2023 ASHWITHA PROJECTS PRIVATE LIMITED All Rights
                Reserved
              </div>{" "}
              <div className="col-md-6" style={{ float: "right" }}>
                Designed by <a
                  href="https://digitalraiz.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  Digitalraiz creative solutions
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
