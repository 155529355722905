import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Load from "../images/load.gif"

function Blog() {
  const api = "https://api.starroutes.com/";

  const [isLoading, setIsLoading] = useState(false);

  const [Blog, setBlog] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setBlog(res.data.blog);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  const navigate = useNavigate();

  const databyid2 = (data) => {
    sessionStorage.setItem("blogid", data._id);
    navigate("/viewBlog");
  };

  return (
    <div>{isLoading == true ? (
      <>
        <div class="centers">
         
          <img src={Load}  height="220px" ></img>
        </div>
      </>
    ) : (
      <>
      <Header />{" "}
      <Container fluid className="mt-5" style={{ background: "white" }}>
        <Row className="mt-5">
          <Col md={12}>
            <img src="assets/b4.jpg" height="450px" width="100%" alt="" />
          </Col>
        </Row>
        <Row className="mt-5 m-5">
          {Blog.map((data, i) => {
            return (
              <>
                <Col md={4} className="mt-3 mb-5" key={i}>
                  <Card className="kjh">
                    <Card.Img
                      variant="top"
                      src={api + data.image}
                      style={{ height: "250px" }}
                    />
                    <Card.Body>
                      <Card.Title>{data.title}...</Card.Title>
                      <Card.Text className="mb-3">
                        <p
                          className="mt-5"
                          dangerouslySetInnerHTML={{
                            __html: data.description.slice(0, 200),
                          }}
                        ></p>
                      </Card.Text>

                      <Button
                        variant="primary"
                        onClick={() => {
                          databyid2(data);
                        }}
                      >
                        Read More
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
      <Footer />
      </>
      )}
    </div>
  );
}

export default Blog;
