import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import Load from "../images/load.gif"

function QuikWalletFAQ() {
  const [security, setsecurity] = useState([]);

  const [generalfaqs, setgeneralfaqs] = useState([]);

  const [paymentrelatedqueries, setpaymentrelatedqueries] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setsecurity(res.data.quickwalletfaq?.quickwalletSecurity);
            setgeneralfaqs(res.data.quickwalletfaq?.quickwalletGeneralfaqs);
            setpaymentrelatedqueries(
              res.data.quickwalletfaq?.quickwalletPaymentrelatedqueries
            );
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("")
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
         
            <img src={Load}  height="220px" ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div class="container main_container faq_container">
            <div className="faq_section">
              <div className="title_text">Quikwallet FAQ</div>
              <div className="accordion_wrapper">
                <div className="faq_type">General FAQ</div>
                {generalfaqs.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}

                <div className="faq_type mt-5">Payment Related Queries</div>

                {paymentrelatedqueries.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}

                <div className="faq_type mt-5">Security</div>
                {security.map((data, i) => {
                  return (
                    <>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header className="accordion">
                            {data.question}
                          </Accordion.Header>
                          <Accordion.Body>{data.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}
              </div>
            </div>

            {/* <div class="faq_section">
          <div class="title_text">Quikwallet FAQ</div>
          <div class="accordion_wrapper">
            <div class="faq_type">General FAQs</div>
            <div class="accordion" id="generalFaqs">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading0">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#generalFaqs0"
                    aria-expanded="false"
                    aria-controls="generalFaqs0"
                  >
                    Can I change my phone number associated with the wallet?
                  </button>
                </h2>
                <div
                  id="generalFaqs0"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading0"
                  data-bs-parent="#generalFaqs"
                >
                  <div class="accordion-body">
                    QuikWallet uses your phone number for identification
                    purposes. We recommend using the same phone number for your
                    login. Changing the phone number will create a new account,
                    and we do not allow the creation of multiple accounts by a
                    single wallet user. Also, QuikWallet credits,
                    card/transaction details of your old account won&#x27;t be
                    reflected in this account.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#generalFaqs1"
                    aria-expanded="false"
                    aria-controls="generalFaqs1"
                  >
                    Why does QuikWallet use my phone number?
                  </button>
                </h2>
                <div
                  id="generalFaqs1"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#generalFaqs"
                >
                  <div class="accordion-body">
                    QuikWallet uses your phone number to identify you as a user
                    on our application. We do not use our user&#x27;s phone
                    number for any third-party marketing purposes.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#generalFaqs2"
                    aria-expanded="false"
                    aria-controls="generalFaqs2"
                  >
                    Can I receive verification codes/notification SMS on a
                    different phone number?
                  </button>
                </h2>
                <div
                  id="generalFaqs2"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#generalFaqs"
                >
                  <div class="accordion-body">
                    Yes, you can. Verification codes/notification SMS will be
                    sent only to your registered phone number.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion_wrapper">
            <div class="faq_type">Payment related queries</div>
            <div class="accordion" id="paymentFaqs">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading0">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs0"
                    aria-expanded="false"
                    aria-controls="paymentFaqs0"
                  >
                    How do I add a card?
                  </button>
                </h2>
                <div
                  id="paymentFaqs0"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading0"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    Click on the draw menu icon (top left) &gt; Go to Cards
                    Click ‘+’ sign (top right) &gt; Enter card details (Card
                    Number, Expiry, Name on Card) &gt; Click ‘Save’
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs1"
                    aria-expanded="false"
                    aria-controls="paymentFaqs1"
                  >
                    How do I delete a card?
                  </button>
                </h2>
                <div
                  id="paymentFaqs1"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    Go to Cards &gt; Select a card &gt; Click on the ‘bin’ icon
                    (for iOS) &gt; Tap and hold the card (for Android) &gt;
                    Select ‘OK’
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs2"
                    aria-expanded="false"
                    aria-controls="paymentFaqs2"
                  >
                    How do I make a payment through wallet?
                  </button>
                </h2>
                <div
                  id="paymentFaqs2"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    Go to Home &gt; Select the options from Spend money
                    (Food/beverages;Retail;Utilities) &gt; Select where you wish
                    to make the payment &gt; Click on QuikPay &gt; Select Wallet
                    &gt; Make payment).
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs3"
                    aria-expanded="false"
                    aria-controls="paymentFaqs3"
                  >
                    How do I recharge a prepaid card?
                  </button>
                </h2>
                <div
                  id="paymentFaqs3"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    Click on the place &gt; Click on the prepaid icon (beside
                    Quik Pay icon) &gt; Enter Amount Click ‘Recharge’ &gt; Enter
                    CVV/CSC &gt; Click on ‘Pay Securely’ &gt; Enter 3D security
                    password &gt; Press ‘Done’ after successful payment
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs4"
                    aria-expanded="false"
                    aria-controls="paymentFaqs4"
                  >
                    How do I pay my Phone bills? Or How do I recharge my phone?
                  </button>
                </h2>
                <div
                  id="paymentFaqs4"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    Click on the draw menu icon &gt; Select Mobile Recharge &gt;
                    Select prepaid or postpaid &gt; Enter required fields and
                    proceed to payment
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#paymentFaqs5"
                    aria-expanded="false"
                    aria-controls="paymentFaqs5"
                  >
                    What are the terms regarding fees &amp; charges:
                  </button>
                </h2>
                <div
                  id="paymentFaqs5"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading5"
                  data-bs-parent="#paymentFaqs"
                >
                  <div class="accordion-body">
                    i. Though STAR ROUTERS does not charge QuikWallet Account Holder
                    any fees as of now, it reserves the right at any time to
                    charge the QuikWallet Account Holder fees/charges for the
                    transactions carried out by him on QuikWallet. Details of
                    applicable fees and charges are subject to change from time
                    to time. ii. Any government charges, or debits, or tax
                    payable as a result of the use of QuikWallet shall be
                    QuikWallet Account Holder’s responsibility. iii. Amounts due
                    and payable by QuikWallet Account Holder, if not paid
                    separately, shall be recovered by STAR ROUTERS from the Available
                    Amount.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion_wrapper">
            <div class="faq_type">Security</div>
            <div class="accordion" id="accountFaqs">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading0">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accountFaqs0"
                    aria-expanded="false"
                    aria-controls="collapse0"
                  >
                    I forgot my password, what to do?
                  </button>
                </h2>
                <div
                  id="accountFaqs0"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading0"
                  data-bs-parent="#accountFaqs"
                >
                  <div class="accordion-body">
                    Click on &#x27;Forgot Password?&#x27; which will send a
                    reset password link to your email. Click on the link to
                    enter a new password.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accountFaqs1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    Can I close my QuikWallet account?
                  </button>
                </h2>
                <div
                  id="accountFaqs1"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#accountFaqs"
                >
                  <div class="accordion-body">
                    You can always unlink your device from QuikWallet. Click on
                    the draw menu &gt; Settings &gt; Select &#x27;Unlink
                    device&#x27; option.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion_wrapper">
            <div class="faq_type">General FAQs</div>
            <div class="accordion" id="walletFeaturesFaqs">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading0">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#walletFeaturesFaqs0"
                    aria-expanded="false"
                    aria-controls="collapse0"
                  >
                    Is my credit/debit card information completely safe on your
                    application and website?
                  </button>
                </h2>
                <div
                  id="walletFeaturesFaqs0"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading0"
                  data-bs-parent="#walletFeaturesFaqs"
                >
                  <div class="accordion-body">
                    QuikWallet is PCI DSS 2.0 certified and uses Verisign
                    certified 128-bit encryption technology. Thousands of users
                    transact on QuikWallet’s secure payment platform every
                    month. You can be rest assured that your online transactions
                    at QuikWallet.com are completely safe.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#walletFeaturesFaqs1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    What is PCI DSS certificate?
                  </button>
                </h2>
                <div
                  id="walletFeaturesFaqs1"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#walletFeaturesFaqs"
                >
                  <div class="accordion-body">
                    PCI DSS 2.0 security norms have been laid down by payment
                    card industry leaders like MasterCard, Visa, Discover, and
                    Amex.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading2">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#walletFeaturesFaqs2"
                    aria-expanded="false"
                    aria-controls="collapse2"
                  >
                    What is verified by VISA (VBV)?
                  </button>
                </h2>
                <div
                  id="walletFeaturesFaqs2"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading2"
                  data-bs-parent="#walletFeaturesFaqs"
                >
                  <div class="accordion-body">
                    VBV is an additional protection offered by Visa in
                    conjunction with your credit card issuing bank. VBV protects
                    your Visa card with a VBV password (also referred to as the
                    3D secure password) that has been created by you. It also
                    provides the added assurance that only you can use your Visa
                    card online. You can simply register your card and choose a
                    3D secure PIN. This PIN will be required whenever you use
                    your card to make Internet purchases. It prevents
                    unauthorized usage of your card on the Internet, ensuring
                    greater security on online purchases.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#walletFeaturesFaqs3"
                    aria-expanded="false"
                    aria-controls="collapse3"
                  >
                    What is MasterCard secure code?
                  </button>
                </h2>
                <div
                  id="walletFeaturesFaqs3"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#walletFeaturesFaqs"
                >
                  <div class="accordion-body">
                    MasterCard Secure Code is a service offered by banks in
                    partnership with MasterCard. This service provides a way to
                    PIN-protect your card usage on the Internet. You can simply
                    register your card and choose a 3D secure PIN. This PIN will
                    be required whenever you use your card to make Internet
                    payments. It prevents unauthorized usage of your card on the
                    Internet, ensuring greater security on online purchases.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#walletFeaturesFaqs4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                  >
                    What is Verisign certificate?
                  </button>
                </h2>
                <div
                  id="walletFeaturesFaqs4"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#walletFeaturesFaqs"
                >
                  <div class="accordion-body">
                    Verisign certified 128-bit encryption technology ensures
                    that any information you enter when transacting on
                    QuikWallet.com is sent in a Secure Socket Layer (SSL)
                    session and is encrypted to protect you against
                    unintentional disclosure to third parties.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          </div>{" "}
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default QuikWalletFAQ;
