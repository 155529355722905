import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Load from "../images/load.gif";

function Main() {
  const [isLoading, setIsLoading] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingss = {
    dots: false,
    infinite: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      companyName: form.companyName,
      yourEnquiry: form.yourEnquiry,
    };

    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              yourEnquiry: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [Sliders, setSliders] = useState([]);

  const [Industry, setIndustry] = useState([]);

  const [Address, setAddress] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.starroutes.com/";

  const datas = () => {
    axios
      .post(
        "https://api.starroutes.com/v1/staroutesapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setSliders(res.data.slide);
            setIndustry(res.data.industry);
            setAddress(res.data.address[0]);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <img src={Load} height="220px"></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="container main_container">
            <div className="section_one">
              <div className="content_slider">
                <Slider {...settingss}>
                  {Sliders.map((data, i) => {
                    return (
                      <div
                        key={i}
                        data-index={-1}
                        tabIndex={-1}
                        className="slick-slide slick-cloned"
                        aria-hidden="true"
                        style={{ width: "14.285714285714286%" }}
                      >
                        <div>
                          <div
                            className="content_wrapper item"
                            tabIndex={-1}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <h1 className="title_text">{data.title}</h1>
                            <p className="sub_text">{data.description}</p>
                            <div className="cta_btn">
                              <a href="">Learn more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <ul style={{ display: "block" }} className="slick-dots">
              <li className="slick-active">
                <button>1</button>
              </li>
              <li className>
                <button>2</button>
              </li>
              <li className>
                <button>3</button>
              </li>
            </ul>

            <div className="section_two">
              <div className="content_wrapper row">
                <div className="col-lg-6 left_sec">
                  <div className="title_text">
                    Powering India’s most innovative players
                  </div>
                  <div className="sub_text">
                    STAR ROUTES is powering many of India’s most innovative
                    offerings in the teen, elderly, transit &amp; healthcare
                    segments using our fully licensed and compliant end-to-end
                    prepaid stack.
                  </div>
                  <a href="prepaid/index.html">
                    <div className="cta_btn">See use cases</div>
                  </a>
                </div>
                <div className="col-lg-6 right_sec">
                  <img src="assets/images/innovative_players.png" alt="" />
                </div>
              </div>
              <div className="logo_wrapper">
                <div className="img_div">
                  <img src="assets/images/visa_grey_logo.svg" alt="" />
                </div>
                <div className="img_div">
                  <img src="assets/images/upi_grey_logo.svg" alt="" />
                </div>
                <div className="img_div">
                  <img src="assets/images/netc_grey_logo.svg" alt="" />
                </div>
                <div className="img_div">
                  <img src="assets/images/nfs_grey_logo.svg" alt="" />
                </div>
                <div className="img_div">
                  <img src="assets/images/rupay_grey_logo.svg" alt="" />
                </div>
                <div className="img_div">
                  <img src="assets/images/pci_dss_grey_logo.svg" alt="" />
                </div>
              </div>
              <div className="licensed_sec">
                <div className="gradiant_background">
                  <div className="content_wrapper">
                    <img src="assets/images/rbi_logo.svg" alt="rbi_logo" />
                    <div className="content">
                      Our <b>RBI licensed</b> prepaid stack has got you covered
                      every step of the way - from onboarding to KYC to vendor
                      management
                    </div>
                  </div>
                </div>
                <div className="img_wrapper"></div>
              </div>
            </div>
            <div className="section_three">
              <div className="content_wrapper row">
                <div className="col-lg-6 col-sm-12 left_sec">
                  <div className="title_text">
                    Issue, Manage &amp; Support with ease
                  </div>
                  <div className="sub_text">
                    <p className="list">
                      <img src="assets/images/Arrow_icon.svg" alt="Arrow" />
                      Available as APIs, SDKs and Plugins for quick integration
                    </p>
                    <p className="list">
                      <img src="assets/images/Arrow_icon.svg" alt="Arrow" />
                      auto scalable
                    </p>
                    <p className="list">
                      <img src="assets/images/Arrow_icon.svg" alt="Arrow" />
                      cloud agnostic
                    </p>
                    <p className="list">
                      <img src="assets/images/Arrow_icon.svg" alt="Arrow" />
                      microservices architecture
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 right_sec">
                  <div className="card_wrapper">
                    <div className="card_row">
                      <div className="card_div">
                        <img
                          src="assets/images/product_setup_icon.svg"
                          alt="Product Setup"
                        />
                        <div className="card_name">
                          program &amp; product setup
                        </div>
                      </div>
                      <div className="card_div">
                        <img
                          src="assets/images/lifecycle_icon.svg"
                          alt="Product Setup"
                        />
                        <div className="card_name">
                          card lifecycle management
                        </div>
                      </div>
                    </div>
                    <div className="card_row">
                      <div className="card_div">
                        <img
                          src="assets/images/usage_management_icon.svg"
                          alt="Product Setup"
                        />
                        <div className="card_name">card usage management</div>
                      </div>
                      <div className="card_div">
                        <img
                          src="assets/images/customer_management_icon.svg"
                          alt="Product Setup"
                        />
                        <div className="card_name">customer management</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_four">
              <div className="content_wrapper row">
                <div className="col-lg-6 col-sm-12 left_sec">
                  <div className="logo_wrapper">
                    <img
                      src="assets/images/upi_logo_custom.svg"
                      alt="UPI logo"
                    />
                    <img
                      src="assets/images/quikwallet_logo_custom.svg"
                      alt="Quikwallet logo"
                    />
                  </div>
                  <div className="title_text">
                    One QuikWallet. <br />
                    Multiple benefits.
                  </div>
                  <div className="sub_text">
                    Link your wallet with a UPI-enabled application for seamless
                    management, control, and tracking day-to-day transactions on
                    the go.
                  </div>
                  <a href="quikwallet/index.html">
                    <div className="cta_btn">Learn more</div>
                  </a>
                </div>
                <div className="col-lg-6 col-sm-12 right_sec">
                  <div className="img_wrapper">
                    <img
                      src="assets/images/mobile_app_screen.png"
                      alt="App screen"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section_five">
              <div className="content_wrapper row">
                <div className="col-lg-6 left_sec">
                  <div className="title_text">
                    Empowering millions of <br />
                    Indian
                  </div>
                  <div className="sub_text">
                    <div className="flip-container">
                      <div className="flipper">
                        <div>
                          <div>Commuters</div>
                        </div>
                        <div>
                          <div>Teens</div>
                        </div>
                        <div>
                          <div>Elderly</div>
                        </div>
                        <div>
                          <div>Influencers</div>
                        </div>
                        <div>
                          <div>parents</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a href="prepaid/index.html">
                    <div className="cta_btn">Learn more</div>
                  </a>
                </div>
                <div className="col-lg-6 right_sec">
                  <div className="img_wrapper">
                    <img src="assets/images/empowering_img.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="section_six">
              <div className="content_wrapper">
                <div className="carousel_section">
                  <div className="title_text">
                    Serving 10+ Industry <br />
                    Verticals
                  </div>

                  <Slider {...settings}>
                    {Industry.map((data, i) => {
                      return (
                        <>
                          <div key={i}>
                            <div>
                              <div
                                className="item"
                                tabIndex={-1}
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  src={api + data.image}
                                  alt="Volopay_logo"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>

                  <Slider {...settings1} className="pt-5">
                    {Industry.map((data, i) => {
                      return (
                        <>
                          <div key={i}>
                            <div>
                              <div
                                className="item"
                                tabIndex={-1}
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  src={api + data.image}
                                  alt="Volopay_logo"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="section_seven">
              <div className="content_wrapper row">
                <div className="col-lg-6 left_sec">
                  <div className="title_text">Don’t take our word for it</div>
                  <div className="sub_text">
                    Powering some of the best in the business
                  </div>
                </div>
                <div className="col-lg-6 right_sec">
                  <div className="testimonal_slider">
                    <div className="slick-slider slick-initialized">
                      <div className="slick-list">
                        <div
                          className="slick-track"
                          style={{ width: "100%", left: "0%" }}
                        >
                          <div
                            data-index={0}
                            className="slick-slide slick-active slick-current"
                            tabIndex={-1}
                            aria-hidden="false"
                            style={{ outline: "none", width: "100%" }}
                          >
                            <div>
                              <div
                                className="testimonal_card item"
                                tabIndex={-1}
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <div className="sub_text">
                                  At Star Routes, Fastag, prepaid, and fuel card
                                  services have woven themselves into the fabric
                                  of our success story. They have helped us stay
                                  on top of our game by offering unparalleled
                                  efficiency, convenience, and control. Our
                                  testimonial stands as a testament to the
                                  positive impact these services have had on our
                                  fleet customers and grow us. We eagerly look
                                  forward to a future where these innovations
                                  continue to drive our growth and excellence.
                                </div>
                                <div className="title_text">
                                  YARRAMALA ARJUN
                                  <br />
                                  <span>CO FOUNDER AND DIRECTOR</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_eight">
              <div className="content_wrapper">
                <div className="title_text">Get in touch with us!</div>
                <div className="form_wrapper">
                  <div className="row">
                    <div className="col-lg-4 left_sec">
                      <div className="contact_details">
                        <div className="location_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="assets/images/Solid-System-Location.png"
                              alt=""
                            />
                          </div>
                          <div className="sub_text">{Address.address}</div>
                        </div>
                        <div className="mobile_num_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="assets/images/Solid-System-Call.png"
                              alt=""
                            />
                          </div>
                          <div className="sub_text">
                            <a href={Address.phone}> {Address.phone}</a>
                          </div>
                        </div>
                        <div className="mail_div flex_div">
                          <div className="img_wrapper">
                            <img
                              src="assets/images/Solid-System-Mail.png"
                              alt=""
                            />
                          </div>
                          <div className="sub_text">
                            <a href={Address.email}>{Address.email}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 right_sec">
                      <form
                        class="livquik_contactUs"
                        onSubmit={(e) => {
                          formsubmit(e);
                        }}
                      >
                        <div class="input_row row">
                          <div class="form_group_input col-lg-6">
                            <input
                              type="text"
                              class="form_input"
                              id="name"
                              placeholder="Name"
                              autocomplete="new-password"
                              name="name"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.name}
                            />
                            <p class="errTxt ">Enter name</p>
                          </div>

                          <div class="form_group_input col-lg-6">
                            <input
                              type="email"
                              class="form_input "
                              id="email"
                              required
                              placeholder="Email address"
                              autocomplete="new-password"
                              name="email"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.email}
                            />
                            <p class="errTxt ">Enter your email</p>
                          </div>
                        </div>
                        <div class="input_row row">
                          <div class="form_group_input col-lg-6">
                            <input
                              type="text"
                              class="form_input "
                              id="companyName"
                              placeholder="Company name"
                              autocomplete="new-password"
                              name="companyName"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.companyName}
                            />
                            <p class="errTxt ">Enter company name</p>
                          </div>
                          <div class="form_group_input col-lg-6">
                            <input
                              type="text"
                              class="form_input"
                              id="phone"
                              placeholder="Phone number"
                              maxlength="10"
                              minLength="10"
                              pattern="[0-9]{10}"
                              autocomplete="new-password"
                              name="phoneNumber"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.phoneNumber}
                            />
                            <p class="errTxt ">Enter your mobile number</p>
                          </div>
                        </div>
                        <div class="input_row row">
                          <div class="form_group_input textarea_input col-lg-12">
                            <textarea
                              class="form_input"
                              id="enquiry"
                              placeholder="Your enquiry"
                              rows="3"
                              required
                              name="yourEnquiry"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.yourEnquiry}
                            ></textarea>
                          </div>
                        </div>
                        <button type="submit" class="form_submit " disabled="">
                          <img
                            src="../../assets/images/telegram_arrow.svg"
                            alt=""
                          />
                        </button>
                        <button
                          type="submit"
                          class="cta_btn form_submit_resp "
                          disabled=""
                        >
                          Submit
                        </button>
                      </form>
                      <div className="respText" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Main;
